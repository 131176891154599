<script>
import VueApexCharts from 'vue-apexcharts'

/**
 * Projections component
 */
export default {
    components: {
        apexchart: () => import('vue-apexcharts')
    },
    props: {
        orderData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            series: [0,0,0],
            chartOptions: {
                legend: {
                    show: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    fontSize: "14px",
                    offsetX: 0,
                    offsetY: 7
                },
                labels: ["Active", "Delivered", "Completed"],
                colors: ["#f672a7", "#f7b84b", "#1abc9c"],
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        };
    },
    created(){
        this.series = [this.orderData.active, this.orderData.delivered, this.orderData.completed ]
    }
};
</script>

<template>
<div class="card">
    <div class="card-body">
        <b-dropdown class="float-right" variant="black" toggle-class="arrow-none card-drop" right>
            <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal"></i>
            </template>
            <!-- item-->
            <b-dropdown-item>Settings</b-dropdown-item>
            <!-- item-->
            <b-dropdown-item>Download</b-dropdown-item>
            <!-- item-->
        </b-dropdown>

        <h4 class="header-title">Order BreakDown</h4>

        <div class="mt-3 text-center" dir="ltr">
            <apexchart class="apex-charts" type="donut" :options="chartOptions" height="312" :series="series"></apexchart>

            <div class="row mt-3">
                <div class="col-4">
                    <p class="text-muted font-15 mb-1 text-truncate">Active</p>
                    <h4><i class="ri ri-recycle-fill text-success"></i>{{orderData.active}}</h4>
                </div>
                <div class="col-4">
                    <p class="text-muted font-15 mb-1 text-truncate">Delivered</p>
                    <h4><i class="ri ri-share-forward-2-fill text-success"></i> {{orderData.delivered}} </h4>
                </div>
                <div class="col-4">
                    <p class="text-muted font-15 mb-1 text-truncate">Completed</p>
                    <h4><i class="ri ri-picture-in-picture-exit-line text-success"></i> {{orderData.completed}} </h4>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end card-box -->
</template>
