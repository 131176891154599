<template>
    <div v-if="isReadyState">
        <div class="row">
            <div v-for="widget in widgetData" :key="widget.text" class="col-lg-3 col-md-6">
                <WidgetChart :number="widget.number" :text="widget.text" :chart-color="widget.chartColor" />
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
        <div class="row">
            <!-- end col -->
            <div class="col-12">
                <Projections :orderData="order_data"/>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>
<script>

import WidgetChart from '@/layouts/components/widgets/Widget-chart.vue'
import Projections from '@/layouts/components/widgets/Projections.vue'

export default {
    components: {
        WidgetChart,
        Projections
    },
    data() {
        return {
            isReadyState:false,
            widgetData: [],
            order_data: {}
        };
    },
    methods: {
        parseOrdersData(result){
            let arrayObj = [
                { number: result.users.buyer , text: "Total Buyers", chartColor: "#1abc9c"},
                { number: result.orders.total , text: "Total Orders", chartColor: "#3bafda"},
                { number: result.orders.draft , text: "Saved Drafts", chartColor: "#f672a7"},
                { number: '$'+result.revenue , text: "Total Revenue", chartColor: "#6c757d"}
              ]
            this.widgetData = arrayObj;
            this.order_data = result.orders;
        },
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          var result = response.data.result
          this.parseOrdersData(result);
          this.isReadyState = true
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    }
};
</script>
